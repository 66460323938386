import RouterPath from "./pages/RouterPath";

const App = () => {
  return (
    <>
      <RouterPath />
    </>
  );
};
export default App;


